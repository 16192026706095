@use 'texts';
@use 'colors';
@use 'outline';
@import 'fonts';

* {
	box-sizing: border-box;

	&:focus {
		outline: none;
	}
}

:root {
	--mango-primary-font: 'PrimaryFont', 'Arial', 'Helvetica', 'sans-serif';
	--mango-secondary-font: 'SecondaryFont', 'Arial', 'Helvetica', 'sans-serif';
	--mango-promotional-font: 'PromotionalFont', 'Arial', 'Helvetica', 'sans-serif';
}

body,
html {
	margin: 0;
	width: 100%;
	height: 100%;
}

a,
body,
button {
	color: colors.$content-default;
}

a {
	@extend %text-body-m-regular-underline;
}

body {
	@extend %text-body-m;
}

/* stylelint-disable-next-line selector-class-pattern */
.mango-primary-font {
	font-family: var(--mango-primary-font);
}

/* referenced from utils/layoutLayers/constants */
#application-layer {
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;
	margin: 0;
	isolation: isolate;
	min-height: 100%;
}

#page-content-layer {
	isolation: isolate;
}

button:focus-visible,
a:focus-visible {
	@extend %default-outline;
}
